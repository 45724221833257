import styled from '@emotion/styled';
import { intersection } from 'lodash';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
import { useAsiajyeStockOriginal } from '~/pages/asiajye_stock/_private/function/useAsiajyeStockOriginal';
import { useVirtualAccountOrderPre, useVirtualAccountHolding, } from '~/modules/virtualExchange/useVirtualExchangeResource';
export const ConditionAsiajye = memo(function ConditionAiStock() {
    /** 即時訊號商品 */
    const latestTradeString = useSnapshot(staticStore).tradedDate.intraday;
    const baseDate = useSnapshot(staticStore).tradedDate.day0.format('YYYY-MM-DD');
    const baseDateYesterday = useSnapshot(staticStore).tradedDate.days?.[1].format('YYYY-MM-DD');
    const realTimeLongSymbol = useAsiajyeStockOriginal({
        bs: 'b',
        date: latestTradeString.format('YYYY-MM-DD'),
        volume_gte: 3000,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
    })?.data?.symbols;
    const realTimeShortSymbol = useAsiajyeStockOriginal({
        bs: 's',
        date: latestTradeString.format('YYYY-MM-DD'),
        volume_gte: 3000,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
    })?.data?.symbols;
    // 已持有部位
    const longStockHolding = useVirtualAccountHolding('asiajye_stock_original');
    const shortStockHolding = useVirtualAccountHolding('asiajye_stock_original');
    // console.log('longStockHolding', longStockHolding?.find(item => item.symbol === '2233'))
    // 今天進場的部位
    const longOrderPreStock = useVirtualAccountOrderPre('asiajye_stock_original', 'B', {
        dateTime: baseDate,
    })
        ?.filter(datum => datum.bs === 'B')
        .map(datum => datum.symbol);
    const shortOrderPreStock = useVirtualAccountOrderPre('asiajye_stock_original', 'S', {
        dateTime: baseDate,
    })
        ?.filter(datum => datum.bs === 'S')
        .map(datum => datum.symbol);
    const aiOrderLongEntry = realTimeLongSymbol?.reduce((acc, cur) => {
        if (longStockHolding?.some(item => item.symbol === cur)) {
            return acc;
        }
        if (longOrderPreStock?.some(item => item === cur)) {
            return acc;
        }
        acc.push(cur);
        return acc;
    }, []);
    const aiOrderShortEntry = realTimeShortSymbol?.reduce((acc, cur) => {
        if (shortStockHolding?.some(item => item.symbol === cur)) {
            return acc;
        }
        if (shortOrderPreStock?.some(item => item === cur)) {
            return acc;
        }
        acc.push(cur);
        return acc;
    }, []);
    const data = useDailyRankResource({
        date: latestTradeString.format('YYYY-MM-DD'),
        volume_gte: 3000,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
        sort_by: 'volume',
        limit: 2000,
    }, 0);
    const allStock = data.data?.map(a => a.symbol);
    const symbolLong = intersection(allStock, aiOrderLongEntry).slice(0, 5); //5
    const symbolShort = intersection(allStock, aiOrderShortEntry).slice(0, 3); //3
    return (<styleds.container>
      <styleds.listTitle fill='#c85d4a'>多方標的</styleds.listTitle>
      <styleds.listContent fill='#c85d4a33'>
        <SimpleQuoteListInteractWithChart data={symbolLong} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
      <styleds.listTitle fill='#009900'>空方標的</styleds.listTitle>
      <styleds.listContent fill='#00990033'>
        <SimpleQuoteListInteractWithChart data={symbolShort} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 30px calc(50% - 30px) 30px calc(50% - 30px);
  `,
    listTitle: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    color: #ffffff;
    border-radius: 4px;
  `,
    listContent: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    border-radius: 4px;
  `,
};
