import useSWR from 'swr';
import { apirc } from '~/configs/apirc';
export const useAsiajyeStockOriginal = (options, config) => {
    const refreshInterval = config?.refreshInterval || 1000 * 60;
    const { bs, date, volume_gte, volume_lte, amount_gte, amount_lte } = options;
    const res = useSWR(['/api/screener/asiajye_original', bs, date, volume_gte, volume_lte, amount_gte, amount_lte], async () => {
        if (!bs || !date)
            return;
        const $res = await apirc.marketInsightService.GET('/api/screener/asiajye_original', {
            params: {
                query: {
                    bs,
                    date,
                    volume_gte,
                    volume_lte,
                    amount_gte,
                    amount_lte,
                },
            },
        });
        return $res.data;
    }, {
        refreshInterval,
    });
    return res;
};
